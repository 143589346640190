<template>
    <div>
        <page-title :heading="$t('erp.lang_nav_voucherCards') " :subheading="$t('erp.lang_nav_voucherCards')" :icon=icon></page-title>
        <div class="app-main__inner">
        <gift-card></gift-card>
        </div>
    </div>
</template>

<script>
    import PageTitle from "../../../Layout/Components/PageTitle.vue";
    import GiftCard from "../../../components/erp/baseData/GiftCards";

    export default {
        components: {
            PageTitle,
            GiftCard
        },

        data: () => ({
            icon: 'pe-7s-credit icon-gradient bg-tempting-azure',
        })
    }
</script>